.btn-plain {
  color: inherit;
  background: none;
  padding: 0;
  border: 0;
}

.btn-outline-secondary {
  color: $body-color;
}
