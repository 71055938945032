
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        
















$modal-inner-padding: rem(32);
$modal-backdrop-bg: rgba($black, 0.5);
$modal-backdrop-opacity: 1;

@import '../../../node_modules/bootstrap/scss/modal';

.modal-backdrop {
  backdrop-filter: blur(2px);
}
