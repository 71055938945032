.table {
  > thead {
    vertical-align: inherit;
  }

  > :not(:first-child) {
    border-top: 1px solid $table-border-color;
  }
  
  tbody:not(:first-child) {
    border-top: 0;
  }
}

.table-responsive {
  .table {
    margin-bottom: 0;

    > :last-child {
      > tr:last-child {
        td {
          border-bottom: 0;
        }
      }
    }
  }
}
