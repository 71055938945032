
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        









































svg {
  font-size: rem(64);
}
