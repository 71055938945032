
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        







































.trigger {
  .v-popper--shown & {
    svg {
      transform: rotate(180deg);
    }
  }
}
