
:root {
  @include media-breakpoint-down(sm) {
    font-size: rem(14);
  }
}

svg {
  &:not([width]):not(height) {
    width: 1em;
    height: 1em;
  }
}
