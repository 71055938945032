.is-loading {
  @include gradient-striped();
  background-size: $progress-height $progress-height;
  animation: $progress-bar-animation-timing progress-bar-stripes;
  cursor: wait;
}

.is-loading-primary {
  @include gradient-striped(rgba($primary, 0.15));
}

.text-hover-primary {
  color: inherit;

  &:hover,
  &:focus {
    color: $primary;
  }
}
