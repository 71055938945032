
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        





























main {
  @include media-breakpoint-up(md) {
    width: rem(640);
  }
}

.inner {
  width: rem(300);
}

.img {
  height: 85px;
  background: url(../assets/img/login-img.jpg) top center/768px;

  @media (min-resolution: 192dpi) {
    background-size: 384px;
    height: 78px;
  }

  @include media-breakpoint-up(md) {
    height: 100%;
    background-position: top left;
  }
}
