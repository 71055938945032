
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        








.v-popper--theme-dropdown {
  .v-popper__inner {
    padding: rem(0);

    button {
      width: 100%;

      &:hover,
      &:focus {
        background: tint-color($primary, 90%);
      }
    }
  }
}
