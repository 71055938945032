
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        




























































































































































































aside {
  color: tint-color($body-color, 20%);
}

.backdrop {
  display: none;
  background-color: rgba($black, 0.5);
  backdrop-filter: blur(2px);

  .is-visible & {
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

.inner {
  background: radial-gradient(
      ellipse at 100%,
      tint-color($primary, 90%),
      transparent
    )
    $body-bg;
  width: rem(280);
  max-width: calc(100vw - #{rem(52)});
  overflow-y: auto;
  z-index: $zindex-fixed;

  @include media-breakpoint-down(lg) {
    display: none;
    position: fixed;
  }

  .is-visible & {
    @include media-breakpoint-down(lg) {
      display: block;
    }
  }
}

a {
  &:hover,
  &:focus {
    background: tint-color($primary, 90%);
  }

  &.router-link-active {
    color: $primary;

    &::before {
      content: '';
      width: rem(4);
      height: 100%;
      background: $primary;
      border-radius: rem(2);
      position: absolute;
      left: rem(-24);
    }
  }
}
